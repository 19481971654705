<template>
  <div class="design-page">
    <AppCard
      class="design-page__panel"
      body-class="flex column q-pa-lg"
      header-color="bg-grey-11"
      :title="$t('label.design')"
      bordered
    >
      <div class="form-builder__option form-builder__option_horizontal">
        <AppToggle
          v-model="formPart.formBackgroundTransparent"
          :label="$t('label.transparentBackground')"
          left-label
        />
        <AppColorPicker
          v-if="!formPart.formBackgroundTransparent"
          v-model="formPart.formBackgroundColor"
          :label="$t('label.formBackground')"
        />
      </div>
      <div class="form-builder__option form-builder__option_horizontal relative-position">
        <AppColorPicker
          v-model="formPart.primaryColor"
          :label="$t('label.primaryColor')"
        />
        <AppColorPicker
          v-model="formPart.primaryColorHover"
          :label="$t('label.primaryColorHover')"
        />
      </div>
      <div class="form-builder__option form-builder__option_horizontal">
        <AppToggle
          v-model="formPart.advancedDesignSettings"
          :label="$t('label.advancedDesignSettings')"
          left-label
        />
      </div>

      <template v-if="formPart.advancedDesignSettings">
        <div class="form-builder__option form-builder__option_horizontal">
          <AppSliderInput
            v-model="formPart.labelFontSize"
            :label="$t('label.labelFontSize')"
          />
          <AppColorPicker
            v-model="formPart.labelColor"
            :label="$t('label.labelColor')"
          />
        </div>
        <div class="form-builder__option form-builder__option_horizontal">
          <AppSliderInput
            v-model="formPart.fieldTextSize"
            :label="$t('label.fieldTextSize')"
          />
          <AppColorPicker
            v-model="formPart.fieldTextColor"
            :label="$t('label.fieldTextColor')"
          />
          <AppColorPicker
            v-model="formPart.fieldPlaceholderColor"
            :label="$t('label.fieldPlaceholderColor')"
          />
          <AppColorPicker
            v-model="formPart.fieldIconColor"
            :label="$t('label.fieldIconColor')"
          />
          <AppColorPicker
            v-model="formPart.fieldBackgroundColor"
            :label="$t('label.fieldBackgroundColor')"
          />
        </div>
        <div class="form-builder__option form-builder__option_horizontal">
          <AppToggle
            v-model="formPart.fieldBorderSettings"
            :label="$t('label.showFieldBorderSettings')"
            left-label
          />
          <template v-if="formPart.fieldBorderSettings">
            <div class="flex items-center justify-between">
              <div>{{ $t('label.fieldBorder') }}</div>
              <AppButtonToggle
                v-model="formPart.fieldBorder"
                :options="fieldBorderOptions"
                class="toggle-buttons"
                toggle-color="btn-toggle-selected"
                toggle-text-color="black"
                text-color="black"
                padding="13px 18px"
                multiple
              />
            </div>
            <AppSliderInput
              v-model="formPart.fieldBorderRadius"
              :label="$t('label.fieldBorderRadius')"
              :min="0"
              :max="49"
            />
            <AppColorPicker
              v-model="formPart.fieldBorderColor"
              :label="$t('label.fieldBorderColor')"
            />
            <AppSliderInput
              v-model="formPart.fieldBorderSize"
              :label="$t('label.fieldBorderSize')"
              :min="1"
              :max="10"
            />
            <AppSliderInput
              v-model="formPart.fieldVerticalSpacing"
              :label="$t('label.verticalSpacing')"
              :min="1"
              :max="100"
            />
          </template>
        </div>
        <div class="form-builder__option form-builder__option_horizontal">
          <AppSliderInput
            v-model="formPart.submitTextSize"
            :label="$t('label.submitTextSize')"
            :min="8"
            :max="50"
          />
          <AppColorPicker
            v-model="formPart.submitTextColor"
            :label="$t('label.submitTextColor')"
          />
          <AppToggle
            v-model="formPart.submitBorderSettings"
            :label="$t('label.showSubmitSettings')"
            left-label
          />
          <template v-if="formPart.submitBorderSettings">
            <AppColorPicker
              v-model="formPart.submitTextColorHover"
              :label="$t('label.submitTextColorHover')"
            />
            <AppSliderInput
              v-model="formPart.submitBorderRadius"
              :label="$t('label.submitBorderRadius')"
              :min="0"
              :max="49"
            />
            <AppColorPicker
              v-model="formPart.submitBorderColor"
              :label="$t('label.submitBorderColor')"
            />
            <AppSliderInput
              v-model="formPart.submitBorderSize"
              :label="$t('label.submitBorderSize')"
              :min="0"
              :max="20"
            />
            <AppColorPicker
              v-model="formPart.submitIconColor"
              :label="$t('label.submitIconColor')"
            />
            <AppColorPicker
              v-model="formPart.submitIconColorHover"
              :label="$t('label.submitIconColorHover')"
            />
          </template>
        </div>
        <div
          v-if="form.settings.hasMultistep"
          class="form-builder__option form-builder__option_horizontal"
        >
          <AppColorPicker
            v-model="formPart.backIconColor"
            :label="$t('label.backIconColor')"
          />
          <AppColorPicker
            v-model="formPart.backIconColorHover"
            :label="$t('label.backIconColorHover')"
          />
          <AppSliderInput
            v-model="formPart.backBorderRadius"
            :label="$t('label.backBorderRadius')"
            :min="0"
            :max="49"
          />
          <AppColorPicker
            v-model="formPart.backBorderColor"
            :label="$t('label.backBorderColor')"
          />
          <AppColorPicker
            v-model="formPart.backBorderColorHover"
            :label="$t('label.backBorderColorHover')"
          />
          <AppColorPicker
            v-model="formPart.backBackgroundColor"
            :label="$t('label.backBackgroundColor')"
          />
          <AppColorPicker
            v-model="formPart.backBackgroundColorHover"
            :label="$t('label.backBackgroundColorHover')"
          />
        </div>
        <div
          v-if="showOptionalColors"
          class="form-builder__option form-builder__option_horizontal"
        >
          <AppColorPicker
            v-if="form[ENUMS.STEPS.SETTINGS].hasTermsConfirmation"
            v-model="formPart.termsConfirmationTextColor"
            :label="$t('label.termsConfirmationTextColor')"
          />
          <AppColorPicker
            v-if="form[ENUMS.STEPS.SETTINGS].hasNewsletterConfirmation"
            v-model="formPart.newsletterConfirmationTextColor"
            :label="$t('label.newsletterConfirmationTextColor')"
          />
          <AppColorPicker
            v-if="form[ENUMS.STEPS.SETTINGS].hasAffiliateBadge"
            v-model="formPart.affiliateBadgeTextColor"
            :label="$t('label.affiliateBadgeTextColor')"
          />
        </div>
      </template>
    </AppCard>

    <div class="page-hint">
      {{ $t('text.designPageHint') }}
    </div>
    <div class="page-hint page-hint-primary-color">
      {{ $t('text.designPagePrimaryColorHint') }}
    </div>

    <div
      v-if="form.settings.hasPopup"
      class="relative-position"
    >
      <AppCard
        class="design-page__panel"
        body-class="flex column q-pa-lg"
        header-color="bg-grey-11"
        :title="$t('label.popupDesign')"
        bordered
      >
        <div class="form-builder__option form-builder__option_horizontal">
          <AppColorPicker
            v-model="formPart.popupOverlayBackground"
            :label="$t('label.popupOverlayBackground')"
          />
          <AppSliderInput
            v-model="formPart.popupOpacity"
            :label="$t('label.popupOpacity')"
            :min="0"
            :max="100"
          />
          <AppSliderInput
            v-model="formPart.popupBorderRadius"
            :label="$t('label.popupBorderRadius')"
            :min="0"
            :max="49"
          />
          <AppColorPicker
            v-model="formPart.popupBackground"
            :label="$t('label.popupBackground')"
          />
        </div>
        <div class="form-builder__option form-builder__option_horizontal">
          <AppSliderInput
            v-model="formPart.popupHeaderTextSize"
            :label="$t('label.popupHeaderTextSize')"
            :max="64"
          />
          <AppColorPicker
            v-model="formPart.popupHeaderTextColor"
            :label="$t('label.popupHeaderTextColor')"
          />
          <AppColorPicker
            v-model="formPart.popupHeaderIconColor"
            :label="$t('label.popupHeaderIconColor')"
          />
        </div>
        <div class="form-builder__option form-builder__option_horizontal">
          <AppSliderInput
            v-model="formPart.popupButtonTextSize"
            :label="$t('label.popupButtonTextSize')"
            :max="64"
          />
          <AppColorPicker
            v-model="formPart.popupButtonTextColor"
            :label="$t('label.popupButtonTextColor')"
          />
          <AppColorPicker
            v-model="formPart.popupButtonTextColorHover"
            :label="$t('label.popupButtonTextColorHover')"
          />
        </div>
        <div class="form-builder__option form-builder__option_horizontal">
          <AppColorPicker
            v-model="formPart.popupButtonBackgroundColor"
            :label="$t('label.popupButtonBackgroundColor')"
          />
          <AppColorPicker
            v-model="formPart.popupButtonBackgroundColorHover"
            :label="$t('label.popupButtonBackgroundColorHover')"
          />
        </div>
        <div class="form-builder__option form-builder__option_horizontal">
          <AppSliderInput
            v-model="formPart.popupButtonBorderRadius"
            :label="$t('label.popupButtonBorderRadius')"
            :min="0"
            :max="49"
          />
          <AppColorPicker
            v-model="formPart.popupButtonBorderColor"
            :label="$t('label.popupButtonBorderColor')"
          />
        </div>
        <div class="form-builder__option form-builder__option_horizontal">
          <AppColorPicker
            v-model="formPart.popupButtonIconColor"
            :label="$t('label.popupButtonIconColor')"
          />
          <AppColorPicker
            v-model="formPart.popupButtonIconColorHover"
            :label="$t('label.popupButtonIconColorHover')"
          />
        </div>
      </AppCard>

      <div class="page-hint">
        {{ $t('text.designPagePopupHint') }}
      </div>
    </div>
  </div>
</template>

<script>
import AppCard from 'common/components/AppCard';
import AppColorPicker from 'common/components/AppColorPicker';
import AppSliderInput from 'common/components/AppSliderInput';
import AppToggle from 'common/components/AppToggle';
import AppButtonToggle from 'common/components/buttons/AppButtonToggle';
import { builderMixin } from 'pages/dashboard/form/mixins';

export default {
  name: 'DesignPage',
  stepName: ENUMS.STEPS.DESIGN,
  components: {
    AppButtonToggle,
    AppCard,
    AppColorPicker,
    AppSliderInput,
    AppToggle,
  },
  mixins: [builderMixin],
  data() {
    return {
      fieldBorderOptions: [
        { label: this.$t('button.left'), value: 'left' },
        { label: this.$t('button.top'), value: 'top' },
        { label: this.$t('button.right'), value: 'right' },
        { label: this.$t('button.bottom'), value: 'bottom' },
      ],
    };
  },
  computed: {
    showOptionalColors() {
      return this.form[ENUMS.STEPS.SETTINGS].hasAffiliateBadge
        || this.form[ENUMS.STEPS.SETTINGS].hasTermsConfirmation
        || this.form[ENUMS.STEPS.SETTINGS].hasNewsletterConfirmation;
    },
  },
};
</script>

<style scoped lang="scss">
.design-page {
  &__panel:not(:last-child) {
    margin-bottom: 20px;
  }

  .color-picker {
    max-width: unset;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1.35fr;
    gap: 20px;

    label {
      align-items: center;
    }
  }
}

.page-hint {
  &-primary-color {
    top: 189px;
  }
}
</style>
