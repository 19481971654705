export default {
  stepName: ENUMS.STEPS.FORM_FIELDS,
  data() {
    return {
      iconPositionOptions: [
        { label: this.$t('button.left'), value: 'left' },
        { label: this.$t('button.right'), value: 'right' },
      ],
    };
  },
  methods: {
    onToggleSection(status, name) {
      this.$root.$emit(ENUMS.EVENTS.ON_TOGGLE_FORM_SETTING, {
        status,
        name,
      });
    },
  },
};
