import { isObjectEquals } from 'common/helpers/objectHelper';
import { debounce } from 'quasar';
import { mapGetters, mapState } from 'vuex';

export default {
  stepName: undefined,
  data() {
    return {
      formPart: undefined,
    };
  },
  computed: {
    ...mapGetters('form', ['getFormPart']),
    ...mapState('form', ['form']),
  },
  created() {
    this.formPart = this.getFormPart(this.$options.stepName);

    this.$watch('form', function (value) {
      // This watcher is required to update state in other components
      // MOST IMPORTANT for pages with several components like FormFieldsPage, ThankYouDialog
      if (isObjectEquals(this.formPart, value[this.$options.stepName])) {
        return;
      }
      this.formPart = this.getFormPart(this.$options.stepName);
    }, { deep: true });

    this.$watch('formPart', debounce(function (value) {
      // This watcher is required to update state in the store
      const key = this.$options.stepName;
      if (isObjectEquals(this.formPart, this.getFormPart(key))) {
        return;
      }

      const changes = {};

      Object.keys(this.form[key]).forEach((property) => {
        if (this.form[key][property] !== value[property]) {
          changes[property] = value[property];
        }
      });

      this.$root.$emit(ENUMS.EVENTS.ON_CHANGE_FORM_PART, {
        key,
        value: changes,
      });

      this.$store.dispatch('form/updateForm', {
        [key]: value,
      });
    }, 250), { deep: true });
  },
};
